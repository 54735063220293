/* eslint-disable */

// Vendor
// import '../../vendor/vendor'

// Components
// import '../../components/components'

// import "../../../../node_modules/jquery/dist/jquery.min.js";
// import "../../../../node_modules/uikit/dist/js/uikit.min.js";
// import "../../../../node_modules/uikit/dist/js/uikit-icons.min.js";
// import "../../../../node_modules/jquery.maskedinput/src/jquery.maskedinput.js";
'use strict'
// import $ from "expose-loader?exposes=$,jQuery!jquery";
import $ from "jquery";
// var $ = require( "jquery" );
// define( [ "jquery" ], function( $ ) {

// } );

import UIkit from "UIkit";
import Inputmask from "inputmask";
import 'lazysizes';
// import Litepicker from  "litepicker";


// import {TweenMax} from "gsap";
// import datetimepicker from "jquery-datetimepicker";
import marquee from "jquery.marquee";


// import Swiper from 'swiper';
import Swiper from 'swiper/bundle';
// import { tree } from "gulp";
import Scrollbar from 'smooth-scrollbar';

import 'jquery-zoom';

$('.klademug').each(function () {
  $(this).prop('Counter', 0).animate({
    Counter: 100
  }, {
    duration: 1000,
    easing: 'swing',
    step: function (now) {
      // if (now == 100) {
      //   $(this).css('color', '#fff')
      // }
      $(this).text(Math.ceil(now) + '%');

    }
  });
});

// window.onload = function () {

// };

setTimeout(() => {
  let preloaderEl2 = document.getElementById('preloader2');
  preloaderEl2.classList.add('hidden');

  const txtHeader = Array.from(document.querySelectorAll('.header-main__p1 > div')).concat(Array.from(document.querySelectorAll('.text-effect')));
  txtHeader.forEach(element => {
    $(element).html("<span>" + $(element).text().split('').join("</span><span>") + "</span>");
  });
  $('.header-main__p1 > div').attr('uk-scrollspy', 'target: > *; cls: uk-animation-slide-bottom; delay: 50;repeat:false;');
  $('.text-effect').attr('uk-scrollspy', 'target: > *; cls: uk-animation-slide-bottom; delay: 100;repeat:false;');
  $('.non-active').removeClass('non-active');
  $('.reg__image').attr('uk-scrollspy', 'cls:uk-animation-slide-bottom; delay: 400');
  $('#delivery__img1 img').attr('uk-scrollspy', 'cls:uk-animation-slide-bottom; delay: 300');
  $('#delivery__img2 img').attr('uk-scrollspy', 'cls:uk-animation-slide-bottom; delay: 600');

  $('.product__img').attr('uk-scrollspy', 'cls:uk-animation-slide-top; delay: 200');

}, 1000);

$(document).ready(function () {

  if($('.search_page').length != 0) {
    $('#kolvo').html($('.goods-item').length)
  }

  function parallax(e, target, layer) {
    var strength = 15;
    var layer_coeff = strength / layer;
    var x = ($(window).width() - $(target).outerWidth()) / 2 - (e.pageX - ($(window).width() / 2)) / layer_coeff;
    var y = ($(window).height() - $(target).outerWidth()) / 2 - (e.pageY - ($(window).height() / 2)) / layer_coeff;
    $(target).offset({
      top: y - y*0.8,
      left: x
    });
  };
  if ($(window).width() >= 1199) {
    $('.not-f__section').mousemove(function (e) {
      parallax(e, document.getElementById(`js-animate-parallax1`), 2);
    });
  }

  // Наведение на фотки в карточке товара
  // $('.product__slider-images').on('mouseenter touchstart', function() {
  //   console.log('aa')
  //   $('.img-zoom img').parent('.img-zoom__cont').unwrap();
  //   $('.img-zoom img').wrap('<span style="display:inline-block"></span>').css('display', 'block').parent().zoom();
  // });
  $('.img-zoom img').wrap('<span style="display:inline-block" class="img-zoom__cont"></span>').css('display', 'block').parent().zoom();
  $('.product_page').hover(function() {
    console.log($('.img-zoom__cont').length == 0);
    if($('.img-zoom__cont').length == 0) {
      $('.img-zoom img').wrap('<span style="display:inline-block" class="img-zoom__cont"></span>').css('display', 'block').parent().zoom();
      
      
    }
  });
  // This button will increment the value
  // $('[data-quantity="plus"]').click(function (e) {
  //   // Stop acting like a button
  //   e.preventDefault();
  //   // Get the field name
  //   const fieldName = $(this).attr('data-field');
  //   // Get its current value
  //   const currentVal = parseInt($('input[name=' + fieldName + ']').val());
  //   // If is not undefined
  //   if (!isNaN(currentVal)) {
  //     // Increment
  //     $('input[name=' + fieldName + ']').val(currentVal + 1);
  //   } else {
  //     // Otherwise put a 0 there
  //     $('input[name=' + fieldName + ']').val(0);
  //   }
  // });
  // This button will decrement the value till 0
  // $('[data-quantity="minus"]').click(function (e) {
  //   // Stop acting like a button
  //   e.preventDefault();
  //   // Get the field name
  //   const fieldName = $(this).attr('data-field');
  //   // Get its current value
  //   const currentVal = parseInt($('input[name=' + fieldName + ']').val());
  //   // If it isn't undefined or its greater than 0
  //   if (!isNaN(currentVal) && currentVal > 0) {
  //     // Decrement one
  //     $('input[name=' + fieldName + ']').val(currentVal - 1);
  //   } else {
  //     // Otherwise put a 0 there
  //     $('input[name=' + fieldName + ']').val(0);
  //   }
  // });

  const passEye = document.querySelectorAll(".pass-eye");
  const oldMethodInput = document.querySelector(".old-method input");

  passEye.forEach(function (el) {
    el.addEventListener("click", () => {
      if (el.nextElementSibling.getAttribute("type") === "password") {
        el.nextElementSibling.setAttribute("type", "text");
        el.classList.add('active');
      } else {
        el.nextElementSibling.setAttribute("type", "password");
        el.classList.remove('active');
      }
    });
  });

  // button.addEventListener("click", () => {
  //   document.documentElement.classList.toggle("show-passwords");
  //   if (oldMethodInput.getAttribute("type") === "password") {
  //     oldMethodInput.setAttribute("type", "text");
  //   } else {
  //     oldMethodInput.setAttribute("type", "password");
  //   }
  // });

  if ($(window).width() <= 639) {
    $('#modal-buy-one-click').attr('uk-modal', 'bg-close: false;');
    $('#modal-entry').attr('uk-modal', 'bg-close: false;');
    $('#modal-pass-remind').attr('uk-modal', 'bg-close: false;');
    $('#modal-pass-remind-send').attr('uk-modal', 'bg-close: false;');
    $('#modal-table-size').attr('uk-modal', 'bg-close: false;');
  }



  if ($(window).width() <= 959) {
    $('#catalog-filter').attr('hidden', '')
  }


  if ($(window).width() >= 640) {
    document.querySelectorAll('.mouse-move').forEach((element) => {
      console.log($(".js-cursor").offset().left)
      element.addEventListener("mousemove", function (event) {
        $(".js-cursor").addClass('active');
        var x, y, z;

        x = event.clientX - 15;
        y = event.clientY - 15;
        z = event.pageY;
        $(".js-cursor").css('left', function () {
          x = event.pageX - 50;
          return x;
        });
        $(".js-cursor").css('top', function () {
          y = event.pageY - 50;
          return y;
        });
      });
      element.addEventListener("mouseleave", function (event) {
        $(".js-cursor").removeClass('active');
      });

    });
  }





  $('.new-hits__marque1').marquee({
    //Если вы хотите всегда анимировать с помощью jQuery
    allowCss3Support: true,
    //работает, когда allowCss3Support имеет значение true - полный список см.http://www.w3.org/TR/2013/WD-css3-transitions-20131119/#transition-timing-function
    css3easing: 'linear',
    //Требуется плагин JQuery. По умолчанию это «линейный»
    easing: 'linear',
    //время паузы до следующего поворота анимации в миллисекундах
    delayBeforeStart: 200,
    //'left', 'right', 'up' or 'down'
    direction: 'left',
    //true или false - следует ли дублировать выделение, чтобы показать эффект продолжения потока
    duplicated: true,
    //скорость в миллисекундах
    duration: 20000,
    //разрыв в пикселях между тикерами
    gap: 0,
    //пауза
    pauseOnCycle: false,
    //при наведении курсора на паузу - с помощью плагина jQuery https://github.com/tobia/Pause
    // pauseOnHover: true,
    startVisible: true
  });
  $('.new-hits__marque2').marquee({
    //Если вы хотите всегда анимировать с помощью jQuery
    allowCss3Support: true,
    //работает, когда allowCss3Support имеет значение true - полный список см.http://www.w3.org/TR/2013/WD-css3-transitions-20131119/#transition-timing-function
    css3easing: 'linear',
    //Требуется плагин JQuery. По умолчанию это «линейный»
    easing: 'linear',
    //время паузы до следующего поворота анимации в миллисекундах
    delayBeforeStart: 200,
    //'left', 'right', 'up' or 'down'
    direction: 'right',
    //true или false - следует ли дублировать выделение, чтобы показать эффект продолжения потока
    duplicated: true,
    //скорость в миллисекундах
    duration: 20000,
    //разрыв в пикселях между тикерами
    gap: 0,
    //пауза
    pauseOnCycle: false,
    //при наведении курсора на паузу - с помощью плагина jQuery https://github.com/tobia/Pause
    // pauseOnHover: true,
    startVisible: true
  });




  $('#file-input').change(function () {
    let maxFileSize = 3000000;
    let file = this.files[0];
    if (this.files && file) {
      var reader = new FileReader();
      if (file.size >= maxFileSize) {
        alert("Вес файла должен быть меньше 3 МБайт");
        this.value = "";
        $('.file-p1').removeClass('active');
        $('.file-p1 span').text('Прикрепить презентацию');
      } else {
        $('.file-p1').addClass('active');
        $('.file-p1 span').text(file.name);
        reader.readAsDataURL(this.files[0]);
      }
    }
  });


  var partnerSlider = new Swiper('.partners__slider', {
    slidesPerView: 'auto',
    spaceBetween: 10,
    slidesOffsetBefore: 0,
    centeredSlides: true,
    centeredSlidesBounds: true,
    breakpoints: {
      639: {
        slidesOffsetBefore: 30,
        spaceBetween: 20,
      },
      1199: {
        slidesOffsetBefore: 40,
      },
    }
  });



  const sliderCounter = document.querySelectorAll('.slider__counter');
  sliderCounter.forEach(element => {
    //Счетчик слайда яхт
    UIkit.util.on(element, 'itemshown', function (e) {
      // $('#yacht__slider1 .slider_number').text(e.detail[0].index + 1);
      $(element).find('.slider_number').text(('0' + (e.detail[0].index + 1)).slice(1));

    });
  });



  function clock() {
    var d = new Date();
    var day = d.getDate();
    var hours = d.getHours();
    var minutes = d.getMinutes();
    var seconds = d.getSeconds();

    var month = new Array("января", "февраля", "марта", "апреля", "мая", "июня",
      "июля", "августа", "сентября", "октября", "ноября", "декабря");
    var month_en = new Array("january", "february", "marth", "april", "may", "june",
      "jule", "august", "september", "october", "november", "december");
    var days = new Array("Воскресенье", "Понедельник", "Вторник", "Среда",
      "Четверг", "Пятница", "Суббота");

    if (day <= 9) day = "0" + day;
    if (hours <= 9) hours = "0" + hours;
    if (minutes <= 9) minutes = "0" + minutes;
    if (seconds <= 9) seconds = "0" + seconds;

    var date_date = day + " " + month[d.getMonth()];
    var date_date_en = day + " " + month_en[d.getMonth()] + " " + d.getFullYear() + ' y.';
    var date_time = hours + ":" + minutes;

    if (document.layers) {
      document.layers.date.document.write(date_time);
      document.layers.date.document.close();
      document.layers.time.document.write(date_time);
      document.layers.time.document.close();
    }
    else {
      if (document.getElementById("day")) {
        document.getElementById("day").innerHTML = date_date;
      }
      if (document.getElementById("day-en")) {
        document.getElementById("day-en").innerHTML = date_date_en;
      }
      if (document.getElementById("time")) {
        document.getElementById("time").innerHTML = date_time;
      }


    }
    setTimeout(clock, 1000);
  }
  clock();


  // видео обычное
  $('.video').parent().click(function () {
    if ($(this).children(".video").get(0).paused) {
      $(this).children(".video").get(0).play();
      $(this).children(".playpause").fadeOut();
    } else {
      $(this).children(".video").get(0).pause();
      $(this).children(".playpause").fadeIn();
    }
  });

  // видео ютуб
  var video = {
    init: function () {
      video.play();
    },
    play: function () {
      $('body').on('click', '#play-video-btn', function (e) {
        video.setSrc(this);
      });
    },
    setSrc: function (el) {
      if (el) {
        var video_id = $(el).data('id');
        var url = '//www.youtube.com/embed/' + video_id + '?enablejsapi=1&version=3&showinfo=0&playerapiid=ytplayer&autoplay=1';
        $('img#embed-video-preview').hide();
        $('#play-video-btn').hide();
        $('iframe#embed-video-frame').attr('src', url).fadeIn();
      }
    }
  }
  video.init();




  // Маска телефона
  if ($('.f_phone').length > 0) {
    Inputmask({ "mask": "+7 (999) 999-99-99" }).mask('.f_phone');
  } else {
    console.log('not found')
  }


  let inputScrollNum = 1;
  // Инпут года
  $('.input_archive .select').each(function () {
    var $this = $(this)
    var $styledSelect = $(this).find('.select-styled');
    var $listItems = $(this).find('li');
    let inputScrollNumCurrent = inputScrollNum;

    $(this).find('.select-options').attr('id', `inputScroll${inputScrollNumCurrent}`)
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
      });
      $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

      console.log(inputScrollNumCurrent);
      let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), { alwaysShowTracks: true });
      inputScroll.update();
    });
    $listItems.click(function (e) {
      $styledSelect.text($(this).text()).removeClass('active');
      $('.select-options').fadeOut('fast');
    });
    $(document).click(function () {
      $styledSelect.removeClass('active');
      $('.select-options').fadeOut('fast');
    });
    inputScrollNum++;
  });

  // $('.input-js-product .select').each(function () {
  //   var $this = $(this)
  //   var $styledSelect = $(this).find('.select-styled');
  //   var $listItems = $(this).find('li');
  //   $styledSelect.text($('li.current-variant a', this).text());
  //   let inputScrollNumCurrent = inputScrollNum;

  //   $(this).find('.select-options').attr('id', `inputScroll${inputScrollNumCurrent}`)
  //   $styledSelect.click(function (e) {
  //     e.stopPropagation();
  //     $('div.select-styled.active').not(this).each(function () {
  //       $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
  //     });
  //     $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

  //     console.log(inputScrollNumCurrent);
  //     let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), { alwaysShowTracks: true });
  //     inputScroll.update();
  //   });
  //   $listItems.click(function (e) {
  //     $styledSelect.text($(this).text()).removeClass('active');
  //     $('.select-options').fadeOut('fast');
  //   });
  //   $(document).click(function () {
  //     $styledSelect.removeClass('active');
  //     $('.select-options').fadeOut('fast');
  //   });
  //   inputScrollNum++;
  // });



  //  Стилизация селекта
  $('.input-js select').each(function () {
    let inputScrollNumCurrent = inputScrollNum;
    let $this = $(this), numberOfOptions = $(this).children('option').length;
    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');
    let $styledSelect = $this.next('div.select-styled');
    $this.attr('select_name') != '' ? $styledSelect.text($this.attr('select_name')) : $styledSelect.text($('option:selected', this).text());
    // $styledSelect.text($this.attr('select_name'));
    let $list = $('<ul />', {
      'id': 'inputScroll' + inputScrollNumCurrent,
      'class': 'select-options'
    }).insertAfter($styledSelect);
    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }
    let $listItems = $list.children('li');
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
      });
      $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

      console.log(inputScrollNumCurrent);
      let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), { alwaysShowTracks: true });
      inputScroll.update();
    });
    $listItems.click(function (e) {
      $styledSelect.addClass('select-styled-black');
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();
      $("input[type='submit'].filterApply").trigger('click');
    });
    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.hide();
    });
    inputScrollNum++;

  });


  //  Стилизация селекта в каталогах
  $('.input-js-catalog select').each(function () {
    let inputScrollNumCurrent = inputScrollNum;
    let $this = $(this), numberOfOptions = $(this).children('option').length;
    $this.addClass('select-hidden');
    $this.wrap('<div class="select"></div>');
    $this.after('<div class="select-styled"></div>');
    let $styledSelect = $this.next('div.select-styled');
    $this.attr('select_name') != '' ? $styledSelect.text($this.attr('select_name')) : $styledSelect.text($('option:selected', this).text());
    // $styledSelect.text($this.attr('select_name'));
    let $list = $('<ul />', {
      'id': 'inputScroll' + inputScrollNumCurrent,
      'class': 'select-options'
    }).insertAfter($styledSelect);
    for (let i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list);
    }
    let $listItems = $list.children('li');
    $styledSelect.click(function (e) {
      e.stopPropagation();
      $('div.select-styled.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.select-options').fadeOut('fast');
      });
      $(this).toggleClass('active').next('ul.select-options').fadeToggle('fast');

      console.log(inputScrollNumCurrent);
      let inputScroll = Scrollbar.init(document.querySelector(`#inputScroll${inputScrollNumCurrent}`), { alwaysShowTracks: true });
      inputScroll.update();
    });
    $listItems.click(function (e) {
      $styledSelect.addClass('select-styled-black');
      e.stopPropagation();
      $styledSelect.text($(this).text()).removeClass('active');
      $this.val($(this).attr('rel'));
      $list.hide();

      window.location = $(this).attr('rel');

    });
    $(document).click(function () {
      $styledSelect.removeClass('active');
      $list.hide();
    });
    inputScrollNum++;

  });




  //стилизация бургера меню
  function menuCheck(item) {
    var menu_attr = $(item).hasClass("uk-open");
    if (menu_attr) {
      $('.header_top_menu').addClass('open');
      $('.header_top_back').addClass('open');
    } else {
      $('.header_top_menu').removeClass('open');
      $('.header_top_back').removeClass('open');
    };
  };

  UIkit.util.on('#offcanvas-nav', 'shown', function () {
    menuCheck('#offcanvas-nav');
  });
  UIkit.util.on('#offcanvas-nav', 'hidden', function () {
    menuCheck('#offcanvas-nav');
  });






  // if ($(window).width() < 640) {
  //   // $('#header_top_back_sticky').attr('uk-sticky', 'top: 50; animation: uk-animation-slide-top;');
  //   $('a[uk-scroll]').attr('uk-scroll', 'offset: 112')
  // }

  $("a.transition").click(function (event) {
    event.preventDefault();
    var linkLocation = this.href;
    $("html").css('background', '#f6f0ef');
    $("body").fadeOut(700, redirectPage);

    function redirectPage() {
      window.location = linkLocation;
    }
  });








  function getDataRentForm(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    var captcha = grecaptcha.getResponse(widgetId1);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    if (!captcha.length) {
      // Выводим сообщение об ошибке
      $('#recaptchaError2').text('* Вы не прошли проверку "Я не робот"');
    } else {
      $.ajax({
        url: action,
        type: "POST",
        data: dataForm,
        success: function () {
          UIkit.modal('#modal-center').hide();
          $('a[href="#modal-center"]').attr('href', '#success')
          UIkit.modal('#success').show();
          setTimeout(function () {
            UIkit.modal('#success').hide();
          }, 3000);
        }
      });
    }
  };
  $('form#zay_form').on('submit', getDataRentForm);

  function getDataRentForm2(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    // var captcha = grecaptcha.getResponse(widgetId1);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    $.ajax({
      url: action,
      type: "POST",
      data: dataForm,
      success: function () {
        UIkit.modal('#modal-pass-remind').hide();
        $('a[href="#modal-pass-remind"]').attr('href', '#modal-pass-remind-send')
        UIkit.modal('#modal-pass-remind-send').show();
        setTimeout(function () {
          UIkit.modal('#modal-pass-remind-send').hide();
        }, 3000);
      }
    });
  };
  $('form#recover').on('submit', getDataRentForm2);

  function getDataRentForm3(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    // var captcha = grecaptcha.getResponse(widgetId1);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    $.ajax({
      url: action,
      type: "POST",
      data: dataForm,
      success: function () {
        UIkit.modal('#modal-buy-one-click').hide();
        $('a[href="#modal-buy-one-click"]').attr('href', '#success')
        UIkit.modal('#success').show();
        setTimeout(function () {
          UIkit.modal('#success').hide();
        }, 3000);
      }
    });
  };
  $('form#buy-one-click').on('submit', getDataRentForm3);

  function getDataRentForm4(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    // var captcha = grecaptcha.getResponse(widgetId1);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    $.ajax({
      url: action,
      type: "POST",
      data: dataForm,
      success: function () {
        // UIkit.modal('#modal-buy-one-click').hide();
        // $('a[href="#modal-buy-one-click"]').attr('href', '#success')
        UIkit.modal('#success').show();
        setTimeout(function () {
          // UIkit.modal('#success').hide();
          document.location.reload();
        }, 1500);
      }
    });
  };
  $('form#email-form').on('submit', getDataRentForm4);

  function getDataRentForm5(e) {
    e.preventDefault();
    var $form = $(this);
    var action = $(this).attr('action');
    var dataForm = $(this).serialize();

    // 1. Получить ответ гугл капчи
    // var captcha = grecaptcha.getResponse(widgetId1);
    // 2. Если ответ пустой, то выводим сообщение о том, что пользователь не прошёл тест.
    // Такую форму не будем отправлять на сервер.
    $.ajax({
      url: action,
      type: "POST",
      data: dataForm,
      success: function () {
        // UIkit.modal('#modal-buy-one-click').hide();
        // $('a[href="#modal-buy-one-click"]').attr('href', '#success')
        UIkit.modal('#success').show();
        setTimeout(function () {
          // UIkit.modal('#success').hide();
          // document.location.reload();
          document.location.href = "http://kalina.r-b-m.ru/";
        }, 1500);
      }
    });
  };
  $('form#nc-reg-form').on('submit', getDataRentForm5);



  if ($('.map').length > 0) {
    console.log($('.map-container').css('width'))
    $('.map .ymap-container').css('width', `${$('.map-container').css('width')}`);

    //Функция создания карты сайта и затем вставки ее в блок с идентификатором &#34;map-yandex&#34;
    function init() {
      if ($(window).width() < 640) {
        var myMapTemp = new ymaps.Map("map-yandex", {
          center: [55.739225, 37.484442], // координаты центра на карте
          zoom: 15, // коэффициент приближения карты
          controls: [] // выбираем только те функции, которые необходимы при использовании
        }),
          ZoomLayout = ymaps.templateLayoutFactory.createClass("<div class='map-controls'><span class='crow'></span><div id='zoom-in' class='btn'><span class='icon-plus'>+</span></div><div id='zoom-out' class='btn'><span class='icon-minus'>-</span></div></div>", {
            build: function () {
              ZoomLayout.superclass.build.call(this), this.zoomInCallback = ymaps.util.bind(this.zoomIn, this), this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this), $("#zoom-in").bind("click", this.zoomInCallback), $("#zoom-out").bind("click", this.zoomOutCallback)
            },
            clear: function () {
              $("#zoom-in").unbind("click", this.zoomInCallback), $("#zoom-out").unbind("click", this.zoomOutCallback), ZoomLayout.superclass.clear.call(this)
            },
            zoomIn: function () {
              var e = this.getData().control.getMap();
              e.setZoom(e.getZoom() + 1, {
                checkZoomRange: !0
              })
            },
            zoomOut: function () {
              var e = this.getData().control.getMap();
              e.setZoom(e.getZoom() - 1, {
                checkZoomRange: !0
              })
            }
          }),
          zoomControl = new ymaps.control.ZoomControl({
            options: {
              layout: ZoomLayout
            }
          });

        myMapTemp.controls.add(zoomControl, {
          float: "none",
          position: {
            top: 125,
            right: 15
          }
        });

        var mapEve = myMapTemp.panes.get("events");
        var mapElem = mapEve.getElement();
        myMapTemp.behaviors.disable("scrollZoom");
        myMapTemp.behaviors.disable('drag');
        ymaps.domEvent.manager.add(mapElem, "touchmove", function (e) {
          1 === e.get("touches").length && (mapElem.style.transition = "opacity .3s",
            mapElem.style.background = "rgba(0, 0, 0, .45)",
            mapElem.style.alignItems = "center",
            mapElem.style.boxSizing = "border-box",
            mapElem.style.color = "white",
            mapElem.style.display = "flex",
            mapElem.style.justifyContent = "center",
            mapElem.style.fontSize = "16px",
            mapElem.style.opacity = "0.0",
            mapElem.style.padding = "25px",
            mapElem.style.textAlign = "center",
            mapElem.style.transition = "opacity .3s",
            mapElem.style.touchAction = "auto",
            mapElem.textContent = 'Чтобы переместить карту проведите по ней двумя пальцами',
            mapElem.style.opacity = "1")
        });
        ymaps.domEvent.manager.add(mapElem, "touchend", function () {
          mapElem.style.transition = "opacity .8s",
            mapElem.style.opacity = "0"
        });

        var myPlacemarkWithContent2 = new ymaps.Placemark([55.739225, 37.484442], {
          //  hintContent: 'Посмотреть адрес',
          //  balloonContent: 'Московская обл. г. Подольск, Т.Ц. Красные ряды'
        }, {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: 'default#imageWithContent',
          // Своё изображение иконки метки.
          iconImageHref: '../img/svg/svg-pin.svg',
          // Размеры метки.
          iconImageSize: [50, 50],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-25, -25],
          // Смещение слоя с содержимым относительно слоя с картинкой.
          iconContentOffset: [115, 115]
        });

      } else {
        var myMapTemp = new ymaps.Map("map-yandex", {
          center: [55.739225, 37.484442], // координаты центра на карте
          zoom: 15, // коэффициент приближения карты
          controls: [] // выбираем только те функции, которые необходимы при использовании
        }),


          ZoomLayout = ymaps.templateLayoutFactory.createClass("<div class='map-controls'><span class='crow'></span><div id='zoom-in' class='btn'><span class='icon-plus'>+</span></div><div id='zoom-out' class='btn'><span class='icon-minus'>-</span></div></div>", {
            build: function () {
              ZoomLayout.superclass.build.call(this), this.zoomInCallback = ymaps.util.bind(this.zoomIn, this), this.zoomOutCallback = ymaps.util.bind(this.zoomOut, this), $("#zoom-in").bind("click", this.zoomInCallback), $("#zoom-out").bind("click", this.zoomOutCallback)
            },
            clear: function () {
              $("#zoom-in").unbind("click", this.zoomInCallback), $("#zoom-out").unbind("click", this.zoomOutCallback), ZoomLayout.superclass.clear.call(this)
            },
            zoomIn: function () {
              var e = this.getData().control.getMap();
              e.setZoom(e.getZoom() + 1, {
                checkZoomRange: !0
              })
            },
            zoomOut: function () {
              var e = this.getData().control.getMap();
              e.setZoom(e.getZoom() - 1, {
                checkZoomRange: !0
              })
            }
          }),
          zoomControl = new ymaps.control.ZoomControl({
            options: {
              layout: ZoomLayout
            }
          });

        myMapTemp.controls.add(zoomControl, {
          float: "none",
          position: {
            top: 285,
            right: 62
          }
        });
        myMapTemp.behaviors.disable("scrollZoom");

        var myPlacemarkWithContent2 = new ymaps.Placemark([55.739225, 37.484442], {
          //  hintContent: 'Посмотреть адрес',
          //  balloonContent: 'Московская обл. г. Подольск, Т.Ц. Красные ряды'
        }, {
          // Опции.
          // Необходимо указать данный тип макета.
          iconLayout: 'default#imageWithContent',
          // Своё изображение иконки метки.
          iconImageHref: '../img/svg/svg-pin.svg',
          // Размеры метки.
          iconImageSize: [80, 80],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          iconImageOffset: [-40, -40],
          // Смещение слоя с содержимым относительно слоя с картинкой.
          iconContentOffset: [115, 115]
        });

      }



      myMapTemp.geoObjects.add(myPlacemarkWithContent2); // помещаем флажок на карту

    }

    // Функция загрузки API Яндекс.Карт по требованию (в нашем случае при наведении)
    function loadScript(url, callback) {
      var script = document.createElement("script");

      if (script.readyState) {  // IE
        script.onreadystatechange = function () {
          if (script.readyState == "loaded" ||
            script.readyState == "complete") {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {  // Другие браузеры
        script.onload = function () {
          callback();
        };
      }
      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    // Основная функция, которая проверяет когда мы навели на блок с классом &#34;ymap-container&#34;
    var ymap = function () {
      // Загружаем API Яндекс.Карт
      loadScript("https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=d59af083-2176-46c1-b2d1-641dc27cbf75", function () {
        // Как только API Яндекс.Карт загрузились, сразу формируем карту и помещаем в блок с идентификатором &#34;map-yandex&#34;
        ymaps.load(init);
      });
    }
    $(function () {
      //Запускаем основную функцию
      ymap();
    });
  }


});






// Анимация до якоря при переходе к другой странице
document.body.scrollTop = 0;
var windowScrolled = false;
$(document).ready(function (e) {
  if (windowScrolled != false || location.hash.length == 0) return;

  setTimeout(() => {
    var name = location.hash.substr(1);
    var name2 = name.split(';');

    if (name2[1] === undefined) {
      name2[1] = 0;
    }
    console.log(name);
    console.log(name2);
    var el = $('#' + name2[0] + ', [name=' + name2[0] + ']');

    console.log(el);

    if (el.length > 0) {
      $('body, html').animate({
        scrollTop: el.offset().top - name2[1]
      }, 1000);

      window.scrolled = true;
    }
  }, 1500);

});

